//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.fade-enter {
	opacity: 0;
	transform: translateY(-50%);
  }
  
  .fade-enter-active {
	opacity: 1;
	transform: translateY(0);
	transition: all 300ms ease-in;
  }
  
  .fade-exit {
	opacity: 1;
	transform: translateY(0);
  }
  
  .fade-exit-active {
	opacity: 0;
	transform: translateY(-50%);
	transition: all 300ms ease-out;
  }
  
  .fade-transition {
	&.fade-enter, &.fade-exit {
	  position: absolute;
	  top: 50%;
	  left: 0;
	  right: 0;
	}
  }
  
  
